import http from '@/utils/http'

const accountPending = {
  active: (email, code) => {
    return http.post('/account-active', { email, code })
  },
  resend: (email, language) =>
    http.post('/account-active/resend', { email, language }),
}

export default accountPending
