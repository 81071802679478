import axios from 'axios'
import Cookies from 'js-cookie'
import store from '@/store'
import { router } from '@/router'
import statusMiddleware from '@/utils/http/statusMiddleware'
import errorMiddleware from '@/utils/http/errorMiddleware'

const _api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})
export const noAuthHttp = axios.create({ baseURL: process.env.VUE_APP_API_URL })

_api.interceptors.request.use((config) => {
  const token = Cookies.get('token')
  if (token) config.headers['Authorization'] = `Bearer ${token}`
  // XXX: redireciona para login se não tiver token (separation of concerns fail)
  else {
    if (router.currentRoute.path != '/account/activate') {
      store.dispatch('globalStore/resetAllStores')
      router.push('/login')
    }
  }

  return config
})

_api.interceptors.response.use(
  (response) => response,
  (error) => {
    const expiredToken = statusMiddleware(error)
    if (expiredToken) return Promise.reject(error)
    errorMiddleware(error?.response?.data?.error)
    store.dispatch('apiManager/setApiError', error?.response?.data)
    return Promise.reject(error)
  }
)

noAuthHttp.interceptors.response.use(
  (response) => response,
  (error) => {
    statusMiddleware(error)
    store.dispatch('apiManager/setApiError', error?.response?.data)
    return Promise.reject(error)
  }
)

export default _api
