const ConfirmAccount = () => import('@/modules/auth/views/ConfirmAccount.vue')

const Module = () =>
  import(/* webpackChunkName: "auth-module" */ '@/modules/auth/Module.vue')
const AuthHome = () =>
  import(/* webpackChunkName: "auth-module" */ '@/modules/auth/views/Auth.vue')

const routes = [
  {
    name: 'auth',
    path: '/',
    component: Module,
    children: [
      {
        path: 'login',
        component: AuthHome,
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'account/activate',
        component: ConfirmAccount,
        meta: {
          requiresAuth: false,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
